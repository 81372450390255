.Dyes-Container {
    display: flex;
    margin: 3%;
    flex-direction: column;
    justify-content: space-evenly;
}
.Onset-Container {
    display: flex;
    margin: 3%;
    flex-direction: column;
    justify-content: space-evenly;
}

.Nav-container {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  background-color: #a33434;
  justify-content: space-around;
}

.link-no-dec {
  text-decoration: none;
  color: white;
  margin: 1vw;
}

.Nav-container li {
  display: flex;
  font-size: 2vh !important;
}
.Nav-container ul {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-around;
  align-content: center;
}

.obj-div {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 5%;
}

.obj-div p {
    border: solid 2px #a33434;
    border-radius: 5px;
    padding: 5%;
}

.citation-p {
    margin: 20px;
}
.note {
    margin-top: 0;
    font-style: italic;
}


.cite-container {
  box-shadow: 0px 0px 10px grey;
  border-radius: 10px;
  background-color: white;
  align-self: center;
  padding: 10px;
  margin: 20px;
 /* border: solid 2px green;*/
}
