.dye-container {
  box-shadow: 0px 0px 10px grey;
  border-radius: 10px;
  background-color: white;
  align-self: center;
  padding: 2%;
 /* border: solid 2px green;*/
}

.dye-container-info {
  box-shadow: 0 0 0 2px black;
  /* display: flex; */
  /* justify-content: center; */
  /* margin: 30px 0; */
}

.event-container-bottom {
  display: block;
  margin-top: 5%;
  box-shadow: 7px 3px 10px grey;
  background-color: white;
  width: 90%;
  align-self: center;
  padding: 2%;
}

.center {
  display: flex;
  justify-content: center;
}
.center-div {
  /* border: solid 2px #a33434; */
  justify-content: center;
}
.exc-card-container-big {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.exc-card-container-big-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-shadow: 0 0 0 2px black;
}
.exc-card-container {
  display: flex;
  flex-direction: column;
  width: 15%;
}
.exc-card-container-method {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding-left: 2%;
  padding-right: 2%;
}

.vl {
  border-left: 2px solid black;
  padding: 2%;
}
